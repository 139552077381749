<template>
  <div>
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal"
      id="exampleModal-1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Please Confirm</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="modal-main-text">
              <p class="pb-4 mb-0">Are you sure want to logout ?</p>

              <div class="modal-logout">
                <button
                  type="button"
                  class="btn btn-primary"
                  id="logout-blank"
                  @click="logout('hardlogout')"
                  :disabled="disable"
                >
                  <div v-if="loading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <span v-else>Logout from all devices</span>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="logout('logout')"
                  :disabled="disable"
                >
                  <div v-if="loading2" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <span v-else>Logout from this device</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClass from "../../api/api";

export default {
  name: "Modal",
  data() {
    return {
      loading: false,
      loading2: false,
      disable: false,
    };
  },
  methods: {
    async logout(url) {
      url == "logout" ? (this.loading2 = true) : (this.loading = true);
      this.disable = true;
      var res = await ApiClass.deleteRequest(url, true);
      if (res.data.status_code == 1) {
        this.$store.commit("RESET");
        localStorage.clear();
        this.$refs.Close.click();

        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        console.log("logout");
        // this.$router.go();

        location.replace("/exchange/BTCUSDT");
        // this.$forceUpdate();
        // location.reload();
        //  this.$router.push("/exchange/BTCUSDT")

        this;
      } else {
        this.disable = this.loading2 = this.loading = false;
        this.$refs.Close.click();
      }
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 2rem;
}
.modal {
  background-color: #edf0f2bf;
}

.modal-content {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border: none;
  border-radius: 3px;
}

.modal-header h5 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: rgb(72, 81, 86);
  margin-bottom: 0;
}

.modal-main-text {
  text-align: center;
}

.modal-main-text p {
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  color: rgb(72, 81, 86);
}

.modal-logout {
  display: flex;
  align-items: center;
  justify-content: center;
}

button#logout-blank {
  color: rgb(244, 67, 54);
  background: transparent;
  border-color: rgb(244, 67, 54);
  margin-right: 10px;
}

.modal-logout button.btn.btn-primary {
  height: 50px;
  padding: 0px 14px;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
  box-shadow: none;
  cursor: pointer;
  outline: 0px;
  user-select: none;
  width: 100%;
  background: rgb(244, 67, 54);
  color: #fff;
  border: 1px solid rgb(244, 67, 54);
  margin: 0 auto;
  max-width: 250px;
}

button.btn-close:focus {
  box-shadow: none;
  outline: none;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .modal-logout {
    display: block;
  }
  button#logout-blank {
    margin-bottom: 10px;
  }
}
</style>
