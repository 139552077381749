<template>
    <header id="header_main" class="header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="header__body d-flex justify-content-between">
              <div class="header__left">
                <div class="logo">
                    <router-link class="navbar-brand light" to="/"><img src="../assets/logo-dark.png" class="img-fluid" alt="" />
                </router-link>
                <router-link class="navbar-brand dark" to="/"><img src="../assets/logo-light.png" class="img-fluid" alt="" />
                </router-link>
                </div>
                <div class="left__main"><nav id="main-nav" class="main-nav">
                    <ul id="menu-primary-menu" class="menu">
                     
                      <li class="menu-item" @click="closeToggle">
                        <router-link  to="/cryptosell">Buy Crypto</router-link>
                      </li>
                      <li class="menu-item" @click="closeToggle">
                        <router-link  to="/market">Market</router-link>
                      </li>
                      <li class="menu-item-has-children">
                        <a href="#">Trade</a>

                        <span class="arrow"></span><ul class="sub-menu" style="display: block;">
                          <li class="menu-item" @click="closeToggle">
                            <router-link to="/exchange/BTCUSDT">
                                        Spot</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="menu-item" @click="closeToggle">
                        <router-link class="nav-link" to="/launchpad">Launchpad</router-link>
 </li>

                      <li class="menu-item" @click="closeToggle">
                        <router-link class="nav-link" to="/staking">Staking & Earn</router-link>
                      </li>
                      
                    </ul>
                  </nav>
                  
                  <!-- /#main-nav -->
                </div>
              </div>

              <div class="header__right">
              
                <div class="btn mx-2" v-if="!loggedIn">
                    <router-link to="/login"> Login </router-link>
                </div>
                <div class="btn btn-primary" v-if="!loggedIn">
                  <router-link to="/signup" class="text-white"> Get Started </router-link>
                </div>
                <div class="dropdown notification" v-if="loggedIn">
                  <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="icon-notification"></span>
                  </button>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">

<div class="dropdown-item" v-if="unread > 0">
    <!-- Notification Badge  -->
    <span class="tag badge bg-danger rounded-circle">{{ unread
    }}</span>


</div>
<div class="dropdown-item pb-0" v-if="!item">
    <div class="media pb-0">

        <div class="media-body">
            <div class="data-info">
                <h6 class="text-center">Not Found</h6>
            </div>

        </div>
    </div>
</div>

<div class="dropdown-item" v-for="(item, index) in notification" :key="index">
    <div class="media">

        <div class="media-body">
            <div class="data-info">
                <h6 class="" v-html="item.content"></h6>
            </div>

            <div class="icon-status">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
        </div>
    </div>
</div>

</div>
                </div>
                <div class="mobile-button"><span></span></div>
                <div class="wallet" v-if="loggedIn">
                  <router-link to="/portfolio"> Wallet </router-link>
                </div>
                <div class="dropdown user" v-if="loggedIn" >
                  <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="https://www.svgrepo.com/show/61986/avatar.svg" alt="">
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <router-link @click="closeToggle" to="/settings/profile" class="dropdown-item d-block"><i class="bx bx-user font-size-16 align-middle me-1"></i>
                      <span>Profile</span></router-link>
                      <router-link @click="closeToggle" to="/settings/kyc" class="dropdown-item d-block"><i class="fa fa-info font-size-16 align-middle me-1"></i>
                      <span>Verify Kyc</span></router-link>
                      <router-link @click="closeToggle" to="/settings/ChangePassword" class="dropdown-item d-block"><i class="bx bx-wrench font-size-16 align-middle me-1"></i>
                      <span>Change Password</span></router-link>
                    <div class="dropdown-divider"></div>
                    
                      <a href="#" class="dropdown-item logout" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal-1" id="logout">
                                    <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>  Log Out
                    </a>
                  </div>
                </div>
                <div class="mode-switcher">
                  <a class="sun" href="#" onclick="switchTheme()">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.99993 11.182C9.7572 11.182 11.1818 9.75745 11.1818 8.00018C11.1818 6.24291 9.7572 4.81836 7.99993 4.81836C6.24266 4.81836 4.81812 6.24291 4.81812 8.00018C4.81812 9.75745 6.24266 11.182 7.99993 11.182Z" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M8 1V2.27273" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M8 13.7271V14.9998" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M3.04956 3.04932L3.9532 3.95295" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M12.0469 12.0469L12.9505 12.9505" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M1 8H2.27273" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M13.7273 8H15" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M3.04956 12.9505L3.9532 12.0469" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M12.0469 3.95295L12.9505 3.04932" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </a>
                  <a href="#" class="moon" onclick="switchTheme()">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.0089 8.97241C12.7855 9.64616 12.4491 10.2807 12.0107 10.8477C11.277 11.7966 10.2883 12.5169 9.1602 12.9244C8.03209 13.3319 6.81126 13.4097 5.64056 13.1486C4.46987 12.8876 3.39772 12.2986 2.54959 11.4504C1.70145 10.6023 1.1124 9.53013 0.851363 8.35944C0.590325 7.18874 0.668097 5.96791 1.07558 4.8398C1.48306 3.71169 2.2034 2.72296 3.1523 1.9893C3.71928 1.55094 4.35384 1.21447 5.02759 0.991088C4.69163 1.84583 4.54862 2.77147 4.61793 3.7009C4.72758 5.17128 5.36134 6.55346 6.40394 7.59606C7.44654 8.63866 8.82873 9.27242 10.2991 9.38207C11.2285 9.45138 12.1542 9.30837 13.0089 8.97241Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal/>
    </header>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";

import ApiClass from "../api/api";

export default {
    name: "Navbar",
    components: {
        Modal,
    },

    data() {
        return {
            loggedIn: false,
            toggle: false,
            notification: [],
            unread: ""
        };
    },
    mounted() {
        if (localStorage.getItem("token")) {
            this.loggedIn = true;
            this.getNotifications();
        }
        document.addEventListener('click', this.close)
    },
    methods: {
        closeToggle() {
            const menuToggle = document.getElementById("navbarNav");
            menuToggle.classList.remove("show");
        },
        show() {
            if (this.unread != 0) {
                this.updateNotification();
            }
            this.toggle = !this.toggle;
        },
        async updateNotification() {
            if (localStorage.getItem("token")) {
                let response = await ApiClass.updateFormRequest("notification/read", true);

                if (response.data.status_code != 1) {
                    return;
                }

                this.unread = 0;
            }
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.toggle = false
            }
        },
        async getNotifications() {
            let response = await ApiClass.getRequest("notification/get", true);

            if (response.data.status_code != 1) {
                return;
            }

            if (response?.data) {
                this.unread = response.data.data.totalcount ?? 0;
                this.notification = response.data.data.notifications ?? [];
            }
        }
    },

    async beforeCreate() { },
    watch: {
        $route() {
            if (localStorage.getItem("token")) {
                this.loggedIn = true;
            }
        },
    },
};
</script>

<style scoped>

.logo img{
    max-width: 100px;
}
</style>
