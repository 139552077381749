<template>
<div>
    <section class="banner-sec shadow-sm banner">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-12 mb-5">
                    <div class="banner__content mb-3">
                        <h1 class="title">Bitqix Exchange <br/> trusted <span>cryptocurrency</span> </h1>
                        <p class="fs-20 desc">
                            Bitqix Exchange the most trusted and the easiest, safest, and fastest way to buy & sell crypto asset exchange.
                        </p>

                        <div class="signup-form col-12 col-lg-6">
                            <div class="signup-btn  ">
                                <div class="form-group">
                                    <input type="email" class="form-control w-100 p-3 mb-3 border" id="exampleFormControlInput1" placeholder="Enter Your Email" v-model.trim="$v.email.$model" autocomplete="off" />

                                </div>
                                <!--start-form-box-->
                                <button type="button" @click="nextRegis()" class=" btn btn-primary signup-btn-text">
                                    Get Started
                                </button>
                            </div>
                            <!--signup-btn-->

                            <div class="error text-start" v-if="submitted && !$v.email.required">
                                Email is required
                            </div>
                            <div class="error text-start" v-if="submitted && !$v.email.email">
                                This must be an email
                            </div>
                        </div>
                        <!--signup-form-->
                    </div>
                    <!--banner-info-->
                </div>
                <!--col-xl-8 col-lg-8 col-md-8-->
                <div class="col-lg-4 col-md-12 banner__image d-lg-block d-none">
                    <img src="../assets/banner.png" class="img-fluid"/>

                </div>
            </div>
            <!--row top-row-->
            <section class="crypto p-0">
      <div class="container">
        <div class="row" v-if="skeletonLoad">
                        <div v-for="item in 4" :key="item" class="col-xl-3 col-lg-3 col-md-3">
                            <span class="test-loader" style="display: inline-block">
                                <h3 class="test-loader mb-0">
                                    <VueSkeletonLoader type="rect" :width="130" :height="15" animation="wave" color="#adb5bd" wave-color="#fff" :rounded="true" />
                                    <span style="display: inline-block">
                                        <VueSkeletonLoader type="rect" :width="60" :height="15" animation="wave" color="#adb5bd" wave-color="#fff" :rounded="true" /></span>
                                </h3>
                                <h4 class="mb-0">
                                    <VueSkeletonLoader type="rect" :width="200" :height="15" animation="wave" color="#adb5bd" wave-color="#fff" :rounded="true" />
                                </h4>
                            </span>
                        </div>
                    </div>
        <div class="row" v-else>
          <div class="col-md-12">
            <div class="crypto__main">
              <div class="flat-tabs">
                
                <div class="content-tab">
                     <div class="content-inner gap-4">
                    <div class="crypto-box d-flex align-item-center px-4" v-for="(priceslider, index) in usdtPair.slice(0, 4)" :key="index">
                      <div class="top">
                        <a href="#"> <span><img :src="priceslider.image" width="40"/></span>
                          <span> {{ priceslider.name }}</span>
                          <span class="unit"> {{ priceslider.currency }}/{{
                            priceslider.pair_with
                          }}</span></a>
                              <h6 class="price mt-3 px-2"> {{ priceslider.price }} USD</h6>
                 
                      </div>
                       <div class="bottom">
                           <span class="sale" :style="{
                              background:
                                priceslider.change > 0
                                  ? 'var(--green)'
                                  : 'var(--red)',
                            }">{{ priceslider.change }}%</span>
                      </div>
                    </div>
                    
                </div>
                 
             
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
         
           
        </div>
        <!--container-->
    </section>
    <!--banner-sec-->
   
    <section class="startup-sec">
        <div class="container">
            <div class="row startup-top-row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="subbanner-slider">
                        <VueSlickCarousel v-bind="subbanner" v-if="StartupSliderData.length" :adaptiveHeight="true">
                            <div class="subslider" v-for="(startupslider, index) in StartupSliderData" :key="index">
                                <a :href="startupslider.link" target="blank">
                                    <img :src="startupslider.image" class="rounded shadow-sm img-fluid" />
                                </a>
                            </div>
                        </VueSlickCarousel>
                    </div>
                    <!--subbanner-slider-->
                </div>
                <!--col-xl-12 col-lg-12 col-md-12-->
            </div>
            <!--row startup-top-row-->

             <div class="row startup-middle-row mt-3">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12  mb-3 mt-4">
                    <Heading title="Crypto Market" info="Get access to new digital assets before they appear on other exchanges" />
          
                    <div class="table-responsive card">
                        <table class="table table-borderless text-nowrap">
                            <thead>
                                <tr v-for="(tableheading, index) in TableHeadingData" :key="index">
                                    <th class="image-head" scope="col"></th>
                                    <th scope="col">{{ tableheading.currencyname }}</th>
                                    <th scope="col">{{ tableheading.currencyprice }}</th>
                                    <th class="volume-head" scope="col">
                                        {{ tableheading.currencyvolume }}
                                    </th>
                                    <th scope="col">{{ tableheading.currencychange }}</th>
                                    <th class="action-head text-center" scope="col">
                                        {{ tableheading.currencyaction }}
                                    </th>
                                </tr>
                            </thead>

                            <tbody v-if="tableLoad">
                                <tr v-for="item in 6" :key="item">
                                    <th scope="row">
                                        <VueSkeletonLoader type="circle" :width="30" :height="30" animation="wave" color="#adb5bd" wave-color="#fff" />
                                    </th>
                                    <td v-for="item in 5" :key="item">
                                        <VueSkeletonLoader type="rect" :width="130" :height="15" animation="wave" color="#adb5bd" wave-color="#fff" :rounded="true" />
                                    </td>
                                </tr>
                            </tbody>

                            <tbody v-if="!tableLoad">
                                <tr v-for="(tabledata, index) in usdtPair.slice(0, 6)" :key="index">
                                    <th class="image-head text-center" scope="row">
                                        <div class="currency-main-box" id="currency-image">
                                            <img :src="tabledata.image" alt="" class="img-fluid w-25" />
                                        </div>
                                    </th>
                                    <td>
                                        <div class="currency-main-box" id="currency-name">
                                            <h6>
                                                {{ tabledata.currency }}/{{ tabledata.pair_with }}
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="currency-main-box" id="currency-price">
                                            <h6>${{ tabledata.price }}</h6>
                                        </div>
                                    </td>
                                    <td class="volume-head">
                                        <div class="currency-main-box" id="currency-price">
                                            <h6>${{ parseFloat(tabledata.volume).toFixed(2) }}</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="currency-main-box" id="currency-change">
                                            <h6 :style="{
                            color:
                              tabledata.change > 0
                                ? 'var(--green)'
                                : 'var(--red)',
                          }">
                                                {{ tabledata.change }}%
                                            </h6>
                                        </div>
                                    </td>
                                    <td class="action-head">
                                        <div class="currency-main-box" id="trade-btn">
                                            <router-link :to="'/exchange/' + tabledata.symbol">
                                                <button type="button" class="btn btn-primary">
                                                    Trade
                                                </button>
                                            </router-link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--col-xl-10 col-lg-12 col-md-12-->

                <div class="col-xl-10 col-lg-10 col-md-10 mx-auto">
                    <div class="more-link text-center">
                        <router-link to="/market" class="more-text">View More Market</router-link>
                    </div>
                    <!--more-link-->
                </div>
                <!--col-xl-10 col-lg-10 col-md-10-->
            </div>
            <!--startup-middle-row-->
        </div>
        <!--container-->
    </section>
    <!--startup-sec-->

    <section class="mobile-app-sec bg">
        <div class="container">
           <div class="row align-items-center">
                <div class=" col-lg-6 col-md-7">
                    <Heading title="Trade Anytime, Anywhere" class="mb-0" info="Bitqix has a variety of features that make it an ideal place to buy and sell digital assets" />
      
                    <div class="app-info">
                        <h6 class="pb-4">
                            The <span> Bitqix Exchange App </span>And <span>Website</span><br />
                            Offer You An Easy And Quick Way To 
                            <span>Start Trading</span>
                        </h6>

                        <div class="qr-box">
                         
                            <!--qr-image-->

                            <div class="qr-info">
                              
                                <ul class="link-list  d-flex gap-5 p-0 text-center">
                                    <li>
                                        <a href="#">
                                            <div class="link-list-box">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" style="fill: var(--text)">
                                                    <path d="M19.665 16.811a10.316 10.316 0 0 1-1.021 1.837c-.537.767-.978 1.297-1.316 1.592-.525.482-1.089.73-1.692.744-.432 0-.954-.123-1.562-.373-.61-.249-1.17-.371-1.683-.371-.537 0-1.113.122-1.73.371-.616.25-1.114.381-1.495.393-.577.025-1.154-.229-1.729-.764-.367-.32-.826-.87-1.377-1.648-.59-.829-1.075-1.794-1.455-2.891-.407-1.187-.611-2.335-.611-3.447 0-1.273.275-2.372.826-3.292a4.857 4.857 0 0 1 1.73-1.751 4.65 4.65 0 0 1 2.34-.662c.46 0 1.063.142 1.81.422s1.227.422 1.436.422c.158 0 .689-.167 1.593-.498.853-.307 1.573-.434 2.163-.384 1.6.129 2.801.759 3.6 1.895-1.43.867-2.137 2.08-2.123 3.637.012 1.213.453 2.222 1.317 3.023a4.33 4.33 0 0 0 1.315.863c-.106.307-.218.6-.336.882zM15.998 2.38c0 .95-.348 1.838-1.039 2.659-.836.976-1.846 1.541-2.941 1.452a2.955 2.955 0 0 1-.021-.36c0-.913.396-1.889 1.103-2.688.352-.404.8-.741 1.343-1.009.542-.264 1.054-.41 1.536-.435.013.128.019.255.019.381z"></path>
                                                </svg>
                                                <p>Apple</p>
                                            </div>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#">
                                            <div class="link-list-box">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" style="fill: var(--text)">
                                                    <path d="M18.344 8.71c-.707 0-1.279.572-1.279 1.278v4.53a1.278 1.278 0 1 0 2.558 0v-4.53c0-.706-.572-1.278-1.279-1.278zm-12.688 0c-.707 0-1.279.572-1.279 1.278v4.53a1.278 1.278 0 1 0 2.558 0v-4.53A1.277 1.277 0 0 0 5.656 8.71zm8.889-3.809.768-1.388a.343.343 0 0 0-.133-.47.344.344 0 0 0-.471.135l-.789 1.42c-.588-.235-1.236-.368-1.92-.368s-1.332.132-1.92.367l-.789-1.418a.344.344 0 0 0-.471-.135.345.345 0 0 0-.133.47l.768 1.388C8.072 5.69 7.148 7.099 7.148 8.71c0 .011 0 .021.002.034-.002.006-.002.013-.002.019v.001h9.703V8.71c.001-1.611-.923-3.019-2.306-3.809zM9.76 6.869a.346.346 0 1 1 .005-.693.346.346 0 0 1-.005.693zm4.48 0a.347.347 0 0 1 0-.692.346.346 0 0 1 0 .692zM7.148 16.12c0 .646.525 1.171 1.174 1.171h.586v2.401a1.279 1.279 0 0 0 2.558 0v-2.401h1.066v2.401c0 .707.572 1.278 1.277 1.278.709 0 1.281-.571 1.281-1.278v-2.401h.586c.648 0 1.174-.524 1.174-1.171V9.028H7.148v7.092z"></path>
                                                </svg>
                                                <p>Android</p>
                                            </div>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#">
                                            <div class="link-list-box">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" style="fill: var(--text)">
                                                    <path d="m12.954 11.616 2.957-2.957L6.36 3.291c-.633-.342-1.226-.39-1.746-.016l8.34 8.341zm3.461 3.462 3.074-1.729c.6-.336.929-.812.929-1.34 0-.527-.329-1.004-.928-1.34l-2.783-1.563-3.133 3.132 2.841 2.84zM4.1 4.002c-.064.197-.1.417-.1.658v14.705c0 .381.084.709.236.97l8.097-8.098L4.1 4.002zm8.854 8.855L4.902 20.91c.154.059.32.09.495.09.312 0 .637-.092.968-.276l9.255-5.197-2.666-2.67z"></path>
                                                </svg>
                                                <p>Play</p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!--qr-info-->
                        </div>
                        <!--qr-box-->
                    </div>
                    <!--app-info-->
                </div>
                <!--col-xl-6 col-lg-6 col-md-6-->
                <div class="col-xl-6 col-lg-6 col-md-5 order-first order-sm-1">
                    <div class="mobile-app-image">
                        <img src="../assets/home_images/mobile-app.png" alt="" class="img-fluid" />
                    </div>
                    <!--mobile-app-image-->
                </div>
                <!--col-xl-6 col-lg-6 col-md-6-->
            </div>
            <!--row-->
        </div>
        <!--container-->
    </section>
    <!--mobile-app-sec-->

    <section class="why-choose-sec">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-4 col-lg-4 col-md-8 mx-auto">
                    <div class="why-choose-image">
                        <img src="../assets/home_images/why_choose.png" class="img-fluid" alt="" />
                    </div>
                </div>
                <!--col-xl-4 col-lg-6 col-md-6-->

                <div class="col-xl-8 col-lg-8 col-md-12">
                    <div class="choose-info">
                        <Heading title="Why Choose Us?" info="Proven track record operation by holding the principles of Security, Transparency and Integrity" />

                        <div class="row inner-row">
                            <div class="col-xl-6 col-lg-6 col-md-6 mb-4" v-for="(choosedata, index) in ChooseDataBox" :key="index">
                                <div class="choose-main-box">
                                    <div class="choose-icon">
                                        <img :src="require(`@/assets/${choosedata.chooseicon}`)" alt="" class="img-fluid" />
                                        <h4>{{ choosedata.choosetitle }}</h4>
                                    </div>
                                    <!--choose-icon-->

                                    <div class="choose-info">
                                        <p>{{ choosedata.choosepara }}</p>
                                    </div>
                                    <!--choose-info-->
                                </div>
                                <!--choose-main-box-->
                            </div>
                            <!--col-xl-6 col-lg-6 col-md-6-->
                        </div>
                        <!--row inner-row-->
                    </div>
                    <!--choose-info-->
                </div>
                <!--col-xl-8 col-lg-6 col-md-6-->
            </div>
            <!--row-->
        </div>
        <!--container-->
    </section>
    <!--why-choose-sec-->

    <section class="join-community bg ">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 top-column">
                    <div class="row join-info-row align-items-center">
                      

                        <div class="col-xl-6 col-lg-6 col-md-6 ">
                            <div class="join-image">
                                <img src="../assets/home_images/community-image.png" alt="" class="img-fluid" />
                            </div>
                            <!--join-info-->
                        </div>
                        <!--col-xl-6 col-lg-6 col-md-6-->
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="join-info">
                                <h2 class="mb-0 pb-3">
                                    Grow Your Crypto Portfolio With Bitqix 
                                </h2>
                                <p class="mb-0 fs-5">&bull; Check Exchange Rates</p>
                                <p class="mb-0 fs-5">&bull; Instant Trading Anytime Anywhere</p>
                                <p class="mb-0 fs-5">
                                    &bull; Multi-Platform Support (Android, iOS, Google Play
                                    Store)
                                </p>
                            </div>
                            <!--join-info-->
                        </div>
                        <!--col-xl-6 col-lg-6 col-md-6-->
                    </div>
                    <!--join-info-row-->
                </div>
                <!--col-xl-12 col-lg-12 col-md-12-->

                <!-- <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="row join-link-row justify-content-center">
              <div
                class="col-xl-2 col-lg-2 col-md-2 col-4"
                v-for="(joinlist, index) in JoinData"
                :key="index"
              >
                <a href="javascript:void(0)">
                  <div class="join-link-box">
                    <img
                      :src="require(`@/assets/${joinlist.joinicon}`)"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div> -->
                <!--col-xl-12 col-lg-12 col-md-12-->
            </div>
            <!--row-->
        </div>
        <!--container-->
    </section>
    <!--join-community-->

  
  
</div>
</template>

<script>
import ApiClass from "@/api/api";
// import { Modal } from 'bootstrap';
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import {
    required,
    email
} from "vuelidate/lib/validators";
import Heading from "@/components/Home_New/Heading.vue";
import Choose from "@/assets/jsonfiles/Choose.json";
import Community from "@/assets/jsonfiles/Community.json";
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
    name: "Home",
    components: {
        VueSlickCarousel,
        Heading,
        VueSkeletonLoader,
    },

    data() {
        return {
            email: null,
            email1: null,
            submitted: false,
            submitted1: false,

            skeletonLoad: true,
            tableLoad: true,

            /*--------------LIVE PRICE JS START--------------------------*/

            settings: {
                dots: false,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                verticalSwiping: true,
                 autoplay: false,
                 speed: 4000,
                cssEase: "linear",
            },

            usdtPair: [],

            /*--------------LIVE PRICE JS FINISH--------------------------*/

            /*--------------STARTUP JS START--------------------------*/

            StartupSliderData: [],

            subbanner: {
                dots: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                initialSlide: 0,
                autoplay: true,
                speed: 5000,
                autoplaySpeed: 5000,
                cssEase: "linear",
                pauseOnFocus: true,
                pauseOnHover: true,

                responsive: [{
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },

            TableHeadingData: [{
                currencyname: "Market",
                currencyprice: "Price",
                currencyvolume: "Volume",
                currencychange: "24H Change",
                currencyaction: "Action",
            }, ],
            /*--------------STARTUP JS FINISH--------------------------*/

            /*--------------CHOOSE BOX JS START--------------------------*/

            ChooseDataBox: Choose.ChooseData,
            /*--------------CHOOSE BOX JS FINISH--------------------------*/

            /*--------------COMMUNITY BOX JS START--------------------------*/

            JoinData: Community.CommunityLink,
            /*--------------COMMUNITY BOX JS FINISH--------------------------*/
        };
    },

    validations() {
        return {
            email: {
                required,
                email,
            },
            email1: {
                required,
                email,
            },
        };
    },
    async mounted() {
        // setTimeout(() => {
        //   this.modalElement = new Modal(this.$refs.modalElement);
        //   this.modalElement.show();
        // }, 3000);

        this.generateQRCode();
        var url = ApiClass.baseUrl;
        let ress = await fetch(url + "banner/get").then((result) => result.json());
        if (ress.status_code == "1") {
            this.StartupSliderData = ress.data;
        }
        this.getCoinList();

        // var myModal = document.getElementById("exampleModal");
        //   document.onreadystatechange = function () {
        //   myModal.show();
        // };
    },
    computed: {
        currentTabComponent: function () {
            return "tab-" + this.currentTab.toLowerCase();
        },
    },

    methods: {
        async getCoinList() {
            var response2 = await ApiClass.getNodeRequest("list-crypto/get", false);
            var data2 = await response2.data;
            this.usdtPair = await data2.data.USDT;
            this.skeletonLoad = false;
            this.tableLoad = false;
            // alert(this.tableLoad);
            // alert(this.skeletonLoad)
            // console.log(this.usdtPair);
        },

        nextRegis() {
            //  to="{, params: { email: 123 }}"
            this.submitted = true;
            if (this.$v.email.$invalid) {
                return;
            }
            this.submitted = false;
            this.$router.push({
                name: "SignUp",
                params: {
                    email: this.email,
                },
            });
        },

        nextRegis1() {
            //  to="{, params: { email: 123 }}"
            this.submitted1 = true;
            if (this.$v.email1.$invalid) {
                return;
            }
            this.submitted1 = false;
            this.$router.push({
                name: "SignUp",
                params: {
                    email: this.email1,
                },
            });
        },

        generateQRCode(address) {
            return (
                "https://chart.googleapis.com/chart?cht=qr&chl=" +
                address +
                "&chs=160x160&chld=L|0"
            );
        },
    },
};
</script>
<style>

.choose-info, .join-info{
    color: var(--text);
    font-size: 14px;
}
.choose-icon img{
    margin-bottom: 15px;
}
.slick-slide img{
    width: 100% !important; /* image box size as % of container, see step 1 */
  height: 100% !important; /* image box size as % of container, see step 1 */
  object-fit: cover !important; /* matching of image pixels to image box, see step 2 */
}

</style>

